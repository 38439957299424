import "./VideoItemsWrapper.css";
import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import { splitCapitals } from "../../../scripts/helper";
import VideoItem from "../VideoItem/VideoItem";

const VideoItemsWrapper = (props) => {
  const {
    playlistName,
    totalCount,
    setCurrTotalCount,
    subjectChanged,
    editObjHandler,
    newVideo,
  } = props;
  const [currPage, setCurrPage] = useState(1);
  const [videos, setVideos] = useState([]);
  const [isLoadingVideos, setIsLoadingVideos] = useState(true);

  const authRoute = usePrivateRoute();

  const pages = Math.ceil(totalCount / 10);

  const handleChange = (event, value) => {
    setCurrPage(value);
  };

  useEffect(() => {
    setIsLoadingVideos(true);
    authRoute
      .get(
        `/videos/allfast?subject=${encodeURIComponent(
          playlistName
        )}&page=${currPage}`
      )
      .then((data) => {
        console.log(data.data.data);
        setVideos(data.data.data);
        setIsLoadingVideos(false);
      });
  }, [currPage]);

  const deleteVideoHandler = (id) => {
    setVideos((prev) => {
      let newPrev = [...prev];
      newPrev = newPrev.filter((video) => video._id !== id);
      return newPrev;
    });
    setCurrTotalCount({
      playlistName,
      subjectCountFunc: (count) => count - 1,
      totalCountFunc: (count) => count - 1,
    });
  };

  useEffect(() => {
    if (newVideo?.playlist === playlistName.split(" ").join("")) {
      const index = videos.findIndex((video) => video._id === newVideo._id);

      if (index !== -1) {
        setVideos(videos.map((video, i) => (i === index ? newVideo : video)));
      } else {
        setVideos([newVideo, ...videos]);

        if (!newVideo.oldSubject) {
          setCurrTotalCount({
            playlistName,
            subjectCountFunc: (count) => count + 1,
            totalCountFunc: (count) => count + 1,
          });
        } else {
          subjectChanged(
            splitCapitals(newVideo.oldSubject),
            splitCapitals(newVideo.playlist)
          );
        }
      }
    } else if (newVideo?.oldSubject == playlistName.split(" ").join("")) {
      setVideos((prev) => {
        let newPrev = [...prev];
        newPrev = newPrev.filter((video) => video._id !== newVideo._id);
        return newPrev;
      });
    }
  }, [newVideo]);

  return (
    <div id="videos-wrapper">
      {totalCount == 0 ? (
        <h4>No videos to show</h4>
      ) : !isLoadingVideos ? (
        <div>
          {videos?.map((video) => {
            return (
              <VideoItem
                deleteVideoHandler={deleteVideoHandler}
                key={video._id}
                video={video}
                editVideo={editObjHandler}
              />
            );
          })}
          {pages > 1 ? (
            <Pagination
              id="videos-pagination"
              count={pages}
              onChange={handleChange}
              page={currPage}
              shape="rounded"
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <h4>Loading videos...</h4>
      )}
    </div>
  );
};

export default VideoItemsWrapper;
