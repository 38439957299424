import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Filters from "../Filters";
import "./PDFUploadModal.css";
import { useEffect, useRef, useState } from "react";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import * as bootstrap from "bootstrap";

const PDFUploadModal = (props) => {
  const { editedPDF, setNewPDF } = props;
  const [type, setType] = useState("ADD");

  const [file, setFile] = useState(null);
  const [errTxt, setErrTxt] = useState("");
  const [succText, setSuccText] = useState("");
  const [premium, setPremium] = useState(false);
  const [uploadImageCover, setUploadImageCover] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [desc, setDesc] = useState("");
  const [title, setTitle] = useState("");
  const [pages, setPages] = useState("");
  const [grade, setGrade] = useState("KG");
  const [subject, setSubject] = useState("Math");
  const [topic, setTopic] = useState("Arts & Craft");
  const [isUploading, setIsUploading] = useState(false);
  const [fileType, setFileType] = useState(1);
  const [submitReady, setSubmitReady] = useState(false);
  const galleryInputRef = useRef(null);
  const coverImageInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const authRoute = usePrivateRoute();

  const closeModal = () => {
    const modal = bootstrap.Modal.getInstance(
      document.getElementById("exampleModal")
    );
    modal.hide();
  };

  const config = {
    EDIT: {
      title: "EDIT WORKSHEET",
      cancelBtn: "Close",
      submitBtn: "Save",
      submitBtnSubmitting: "Saving...",
    },
    ADD: {
      title: "ADD NEW WORKSHEET",
      cancelBtn: "Clear",
      submitBtn: "Submit",
      submitBtnSubmitting: "Submitting...",
    },
  };

  const initEditedValues = () => {
    console.log(editedPDF);
    setFile(null);
    fileInputRef.current.value = ""; // Clear the input value

    if (editedPDF.filename) {
      const current_file = new File([new Blob()], editedPDF.filename, {
        type: "application/pdf",
        lastModified: new Date().getTime(),
      });
      const container = new DataTransfer();
      container.items.add(current_file);
      document.getElementById("pdf-input").files = container.files;
    }

    setUploadImageCover(null);
    coverImageInputRef.current.value = ""; // Clear the input value
    setGallery(null);
    galleryInputRef.current.value = "";
    setDesc(editedPDF.description);
    setTitle(editedPDF.title);
    setPages(editedPDF.pages);
    setPremium(editedPDF.premium);
    setGrade(editedPDF.grade);
    setSubject(editedPDF.subject);
  };

  useEffect(() => {
    setType(editedPDF ? "EDIT" : "ADD");
  }, [editedPDF]);

  useEffect(() => {
    (({ EDIT: initEditedValues, ADD: clearFormData })[type] || (() => {}))();
  }, [type]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handlePdfUpdate = async () => {
    Swal.fire({
      title: "Are you sure you want to save your changes?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#26a042",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsUploading(true);

        const basicPDF = {
          title,
          desc,
          grade,
          subject,
          premium,
          pages: pages < 1 ? 1 : pages,
        };

        const pdfWithImages = {
          ...(uploadImageCover && { cover: uploadImageCover }),
          ...(gallery && { gallery: gallery }),
          ...basicPDF,
        };

        authRoute
          .put(`/pdfs/${editedPDF._id}`, pdfWithImages)
          .then((response) => {
            Swal.fire({
              title: "Updated!",
              text: "The worksheet has been updated successfully.",
              icon: "success",
            });
            setNewPDF({ ...response.data, oldSubject: editedPDF.subject });
            setIsUploading(false);
            closeModal();
          });
      }
    });
  };

  const handleFileUpload = async () => {
    Swal.fire({
      title: "Uploading!",
      html: "It might take some time...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setErrTxt("");
    setSuccText("");
    setIsUploading(true);
    try {
      const workbook = {
        pdf: file,
        title,
        premium,
        description: desc,
        grade,
        subject,
        pages: pages < 1 ? 1 : pages,
        topic,
      };

      if (uploadImageCover) {
        workbook.cover = uploadImageCover;
      }
      if (gallery) {
        workbook.gallery = gallery;
      }

      const response = await authRoute.post("/upload/Workbook", workbook, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setNewPDF(response.data);

      console.log("File uploaded successfully");
      Swal.fire({
        title: "Success!",
        text: "Worksheet uploaded successfully!",
        icon: "success",
        confirmButtonText: "Keep uploading",
      }).then((result) => {
        if (result.isConfirmed) {
          clearFormData(true);
        }
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      Swal.fire({
        title: "Error!",
        html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
        icon: "error",
        confirmButtonText: "Try again",
      });
    }
    setIsUploading(false);
  };

  const handleImageChange = async (e) => {
    if (e.target.files.length == 0) {
      setUploadImageCover(null);
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadImageCover(reader.result);
      };
      const file = e.target.files[0];
      reader.readAsDataURL(file);
    }
  };

  const handleGalleryChange = async (e) => {
    setGallery(null);

    if (e.target.files.length > 4) {
      alert("Only 4 images allowed.");
      galleryInputRef.current.value = "";
      e.preventDefault();
      return;
    } else if (e.target.files.length == 0) {
      setGallery(null);
    } else {
      [...e.target.files].forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setGallery((prevImages) => [...(prevImages ?? []), reader.result]);
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const clearFormData = (keep = false) => {
    setErrTxt("");
    setSuccText("");
    setIsUploading(false);
    setFile(null);
    fileInputRef.current.value = ""; // Clear the input value
    setUploadImageCover(null);
    coverImageInputRef.current.value = ""; // Clear the input value
    setGallery(null);
    galleryInputRef.current.value = "";
    setDesc("");
    setTitle("");
    setPages(1);
    setPremium(false);
    setTopic("Arts & Craft");
    if (!keep) {
      setGrade("KG");
      setSubject("Math");
    }
  };

  useEffect(() => {
    if (
      title != "" &&
      ((type == "ADD" &&
        uploadImageCover != null &&
        coverImageInputRef?.current?.value != "" &&
        file != null &&
        fileInputRef?.current?.value != "") ||
        type == "EDIT")
    ) {
      setSubmitReady(true);
    } else {
      setSubmitReady(false);
    }
  }, [
    file,
    fileInputRef,
    premium,
    uploadImageCover,
    coverImageInputRef,
    title,
    pages,
  ]);

  useEffect(() => {
    const modalElement = document.getElementById("exampleModal");
    modalElement.addEventListener("hidden.bs.modal", (e) => {
      clearFormData();
    });

    return () => {
      modalElement.removeEventListener("hidden.bs.modal", (e) => {
        clearFormData();
      });
    };
  }, []);

  useEffect(() => {
    const myModal = new bootstrap.Modal(
      document.getElementById("exampleModal")
    );

    const closeButton = document.querySelector(".modal-close-button");
    closeButton.addEventListener("click", () => {
      myModal.hide();
    });
  }, []);

  const handleCancelButton = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("exampleModal")
    );

    type == "EDIT" ? myModal.hide() : clearFormData();
  };

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div
        className="modal-dialog modal-dialog-centered mw-100 w-50"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {config[type].title}
            </h5>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              className="btn-close btn-close-white modal-close-button"
              {...(isUploading ? { tabIndex: -1 } : {})}
            ></button>
          </div>
          <div className="modal-body pb-0">
            <form>
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  id="floatingInput"
                  placeholder=""
                  value={title}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <label htmlFor="floatingInput">Document title</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  title="This feature is not fully implemented."
                  className="form-control"
                  id="floatingInput"
                  type="number"
                  min={1}
                  placeholder=""
                  value={pages}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                  onChange={(e) => setPages(e.target.value)}
                />
                <label htmlFor="floatingInput">Number of Pages</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className="mb-3 gap-3"
              >
                <div className="w-50">
                  <label htmlFor="floatingPassword">Grade</label>
                  <select
                    value={grade}
                    className="form-select"
                    onChange={(e) => setGrade(e.target.value)}
                    {...(isUploading ? { tabIndex: -1 } : {})}
                  >
                    {Filters.gradeFilters.map((grade, index) => (
                      <option key={index} value={grade.search}>
                        {grade.text}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-50">
                  <label htmlFor="floatingPassword">Subject</label>
                  <select
                    value={subject}
                    className="form-select"
                    onChange={(e) => setSubject(e.target.value)}
                    {...(isUploading ? { tabIndex: -1 } : {})}
                  >
                    {Filters.subjectFilters.map((subject, index) => (
                      <option key={index} value={subject.search}>
                        {subject.text}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className="mb-3 gap-3"
              >
                <div className="w-50">
                  <label htmlFor="image-cover">Cover image</label>
                  <input
                    className="form-control"
                    id="image-cover"
                    accept="image/*"
                    ref={coverImageInputRef}
                    type="file"
                    onChange={(e) => handleImageChange(e)}
                    {...(isUploading ? { tabIndex: -1 } : {})}
                  />
                </div>
                <div className="w-50">
                  <label htmlFor="image-cover">Gallery</label>
                  <input
                    className="form-control"
                    id="image-cover"
                    accept="image/*"
                    multiple
                    ref={galleryInputRef}
                    type="file"
                    onChange={(e) => handleGalleryChange(e)}
                    {...(isUploading ? { tabIndex: -1 } : {})}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                className="mb-3 gap-3"
              >
                <div className="w-50">
                  <label htmlFor="pdf-input">PDF File</label>
                  <input
                    id="pdf-input"
                    ref={fileInputRef}
                    accept=".pdf"
                    type="file"
                    className="form-control"
                    placeholder=""
                    onChange={handleFileChange}
                    {...(isUploading ? { tabIndex: -1 } : {})}
                  />
                </div>
                <div className="w-50 d-grid">
                  <label htmlFor="game-premium-input">Is Premium</label>
                  <div
                    className={`btn ${premium ? "btn-success" : "btn-danger"}`}
                    onClick={(e) => setPremium(!premium)}
                  >
                    {premium ? "Premium" : "Not Premium"}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer border-0">
            <button
              type="button"
              className="btn btn-outline-danger modal-cancel-button"
              id="modal-cancel-button"
              onClick={handleCancelButton}
              {...(isUploading ? { tabIndex: -1 } : {})}
            >
              {config[type].cancelBtn}
            </button>
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "#845adf",
                color: "white",
              }}
              onClick={editedPDF ? handlePdfUpdate : handleFileUpload}
              disabled={isUploading || !submitReady}
              {...(isUploading ? { tabIndex: -1 } : {})}
            >
              {isUploading
                ? config[type].submitBtnSubmitting
                : config[type].submitBtn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFUploadModal;
