import "./VideoUploadModal.css";
import { useEffect, useRef, useState } from "react";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import * as bootstrap from "bootstrap";

const VideoUploadModal = (props) => {
  const { editedVideo, setNewVideo, playlists } = props;
  const [type, setType] = useState("ADD");
  const [isUploading, setIsUploading] = useState(false);

  const [playlist, setPlaylist] = useState(playlists?.[0]?.name);
  const [image, setImage] = useState(null);
  const imageInputRef = useRef(null);
  const [videoEmbed, setVideoEmbed] = useState("");

  const authRoute = usePrivateRoute();

  useEffect(() => {
    if (!playlist) {
      setPlaylist(playlists?.[0]?.name);
    }
  }, [playlists]);

  const createVideoObject = () => {
    return {
      playlist,
      image,
      link: videoEmbed,
    };
  };

  const closeModal = () => {
    const modal = bootstrap.Modal.getInstance(
      document.getElementById("admin-video-modal")
    );
    modal.hide();
  };

  const config = {
    EDIT: {
      title: "EDIT VIDEO",
      cancelBtn: "Close",
      submitBtn: "Save",
      submitBtnSubmitting: "Saving...",
    },
    ADD: {
      title: "ADD NEW VIDEO",
      cancelBtn: "Clear",
      submitBtn: "Submit",
      submitBtnSubmitting: "Submitting...",
    },
  };

  const initEditedValues = () => {
    setPlaylist(editedVideo.playlist);
    setVideoEmbed(`https://www.youtube.com/watch?v=${editedVideo.videoId}`);
    setImage(editedVideo.image);
  };

  useEffect(() => {
    setType(editedVideo ? "EDIT" : "ADD");
  }, [editedVideo]);

  useEffect(() => {
    (({ EDIT: initEditedValues, ADD: clearFormData })[type] || (() => {}))();
  }, [type]);

  const handleVideoUpdate = async () => {
    Swal.fire({
      title: "Are you sure you want to save your changes?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#26a042",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, do it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsUploading(true);
        authRoute
          .patch(`/videos/${editedVideo._id}`, {
            data: JSON.stringify(createVideoObject()),
            headers: {
              "content-type": "application/json",
            },
          })
          .then((response) => {
            Swal.fire({
              title: "Updated!",
              text: "The video has been updated successfully.",
              icon: "success",
            });
            setNewVideo({
              ...response.data,
              oldSubject: editedVideo.playlist,
            });
            setIsUploading(false);
            closeModal();
          })
          .catch((error) => {
            console.error("Error updating file:", error);
            Swal.fire({
              title: "Error!",
              html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
              icon: "error",
              confirmButtonText: "Try again",
            });
            setIsUploading(false);
          });
      }
    });
  };

  const handleVideoUpload = async () => {
    Swal.fire({
      title: "Uploading!",
      html: "It might take some time...",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setIsUploading(true);
    try {
      const response = await authRoute.post(`/videos`, {
        data: JSON.stringify(createVideoObject()),
        headers: {
          "content-type": "application/json",
        },
      });

      setNewVideo(response.data);

      console.log("File uploaded successfully");
      Swal.fire({
        title: "Success!",
        text: "Video uploaded successfully!",
        icon: "success",
        confirmButtonText: "Keep uploading",
      }).then((result) => {
        if (result.isConfirmed) {
          clearFormData();
        }
      });
    } catch (error) {
      console.error("Error uploading file:", error);
      Swal.fire({
        title: "Error!",
        html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
        icon: "error",
        confirmButtonText: "Try again",
      });
    }
    setIsUploading(false);
  };

  const clearFormData = () => {
    setVideoEmbed("");
    setImage(null);
    imageInputRef.current.value = "";
    setPlaylist(playlists?.[0]?.name);
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const modalElement = document.getElementById("admin-video-modal");
    modalElement.addEventListener("hidden.bs.modal", clearFormData);

    return () => {
      modalElement.removeEventListener("hidden.bs.modal", clearFormData);
    };
  }, []);

  useEffect(() => {
    const myModal = new bootstrap.Modal(
      document.getElementById("admin-video-modal")
    );

    const closeButton = document.querySelector(".modal-close-button");
    closeButton.addEventListener("click", () => {
      myModal.hide();
    });
  }, []);

  const handleCancelButton = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("admin-video-modal")
    );

    type == "EDIT" ? myModal.hide() : clearFormData();
  };

  return (
    <div
      className="modal fade"
      id="admin-video-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="admin-video-modal-label"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div
        className="modal-dialog modal-dialog-centered mw-100 w-50"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="admin-video-modal-label">
              {config[type].title}
            </h5>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              className="btn-close btn-close-white modal-close-button"
              {...(isUploading ? { tabIndex: -1 } : {})}
            ></button>
          </div>
          <div className="modal-body pb-0">
            <form>
              <div className="w-100 mb-3">
                <label htmlFor="video-option-context-input">Playlist</label>
                <select
                  id="video-option-context-input"
                  value={playlist}
                  className="form-select"
                  onChange={(e) => setPlaylist(e.target.value)}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                >
                  {playlists &&
                    playlists.map((type, index) => (
                      <option key={index} value={type.name}>
                        {type.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="w-100 mb-3">
                <label htmlFor="image-cover">Cover Image</label>
                <input
                  className="form-control"
                  id="image-cover"
                  accept="image/*"
                  type="file"
                  ref={imageInputRef}
                  onChange={(e) => handleImageChange(e)}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                />
              </div>
              <div className="form-floating mb-3">
                <input
                  id="video-video-embed-input"
                  className="form-control"
                  placeholder=""
                  value={videoEmbed}
                  onChange={(e) => setVideoEmbed(e.target.value)}
                  {...(isUploading ? { tabIndex: -1 } : {})}
                />
                <label htmlFor="video-video-embed-input">Video Link</label>
              </div>
            </form>
          </div>
          <div className="modal-footer border-0">
            <button
              type="button"
              className="btn btn-outline-danger modal-cancel-button"
              id="modal-cancel-button"
              onClick={handleCancelButton}
              {...(isUploading ? { tabIndex: -1 } : {})}
            >
              {config[type].cancelBtn}
            </button>
            <button
              type="button"
              className="btn"
              style={{
                backgroundColor: "#845adf",
                color: "white",
              }}
              onClick={editedVideo ? handleVideoUpdate : handleVideoUpload}
              disabled={isUploading}
              {...(isUploading ? { tabIndex: -1 } : {})}
            >
              {isUploading
                ? config[type].submitBtnSubmitting
                : config[type].submitBtn}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoUploadModal;
