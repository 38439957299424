import React, { useEffect, useState } from "react";
import { CollapsedNavBar, NavBar } from "../components/newNavbar/navbar";
import * as bootstrap from "bootstrap";
import TestimonialUploadModal from "../components/testimonials/TestimonialUploadModal/TestimonialUploadModal";
import TestimonialView from "../components/testimonials/TestimonialView/TestimonialView";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import Swal from "sweetalert2";

const AdminTestimonials = () => {
  const [testimonials, setTestimonials] = useState(null);
  const [originalTestimonials, setOriginalTestimonials] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newTestimonial, setNewTestimonial] = useState(null);

  const authRoute = usePrivateRoute();

  useEffect(() => {
    authRoute.get(`/publictestimonials`).then((response) => {
      setTestimonials(response.data);
      setOriginalTestimonials(response.data);
    });
  }, []);

  const handleOrderChanged = (newTestimonials) => {
    setTestimonials(newTestimonials);
  };

  const saveTestimonialsOrder = () => {
    authRoute
      .patch(
        "/testimonials/reorder",
        testimonials.map(({ _id }, index) => {
          return {
            _id,
            index,
          };
        })
      )
      .then((response) => {
        Swal.fire({
          title: "Updated!",
          text: "The testimonials order has been updated successfully.",
          icon: "success",
        });
        setOriginalTestimonials(testimonials);
      });
  };

  const deleteTestimonial = ({ _id }) => {
    Swal.fire({
      title: "Are you sure you want to delete this testimonial?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#26a042",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        authRoute
          .delete(`/testimonials/${_id}`)
          .then((response) => {
            Swal.fire({
              title: "Deleted!",
              text: "The testimonial has been deleted successfully.",
              icon: "success",
            });

            setTestimonials((prev) => {
              let newPrev = [...prev];
              newPrev = newPrev.filter(
                (testimonial) => testimonial._id !== _id
              );
              return newPrev;
            });
            setOriginalTestimonials((prev) => {
              let newPrev = [...prev];
              newPrev = newPrev.filter(
                (testimonial) => testimonial._id !== _id
              );
              return newPrev;
            });
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            Swal.fire({
              title: "Error!",
              html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
              icon: "error",
              confirmButtonText: "Try again",
            });
          });
      }
    });
  };

  const isOrderChanged = () => {
    if (!originalTestimonials || !testimonials) {
      return false;
    }
    if (originalTestimonials?.length !== testimonials?.length) {
      return true;
    }

    for (let i = 0; i < originalTestimonials.length; i++) {
      if (
        JSON.stringify(originalTestimonials[i]) !==
        JSON.stringify(testimonials[i])
      ) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (!newTestimonial) {
      return;
    }

    const index = testimonials.findIndex(
      (testimonial) => testimonial._id === newTestimonial._id
    );

    if (index !== -1) {
      setTestimonials(
        testimonials.map((testimonial, i) =>
          i === index ? newTestimonial : testimonial
        )
      );
      setOriginalTestimonials(
        testimonials.map((testimonial, i) =>
          i === index ? newTestimonial : testimonial
        )
      );
    } else {
      setTestimonials([...testimonials, newTestimonial]);
      setOriginalTestimonials([...originalTestimonials, newTestimonial]);
    }
  }, [newTestimonial]);

  const showModal = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("admin-testimonial-modal")
    );
    myModal.show();
  };

  useEffect(() => {
    const myModalEl = document.getElementById("admin-testimonial-modal");
    myModalEl.addEventListener("hidden.bs.modal", (event) => {
      setSelectedItem(null);
    });
  }, []);

  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <button
          type="button"
          className="btn"
          style={{
            backgroundColor: "#845adf",
            color: "white",
            fontWeight: 700,
            position: "absolute",
            top: 0,
            right: 0,
            margin: "20px 40px 0 0",
          }}
          onClick={showModal}
        >
          Add New Testimonial
        </button>
        {isOrderChanged() && (
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: "#24b24b",
              color: "white",
              fontWeight: 700,
              position: "absolute",
              top: 0,
              left: 0,
              margin: "20px 0 0 40px",
            }}
            onClick={saveTestimonialsOrder}
          >
            Save Testimonials Order
          </button>
        )}
        <TestimonialView
          testimonials={testimonials}
          setSelectedItem={setSelectedItem}
          showModal={showModal}
          handleOrderChanged={handleOrderChanged}
          deleteTestimonial={deleteTestimonial}
        />
        <TestimonialUploadModal
          editedTestimonial={selectedItem}
          setNewTestimonial={setNewTestimonial}
        ></TestimonialUploadModal>
      </div>
    </div>
  );
};

export default AdminTestimonials;
