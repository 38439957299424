let gameTypes = [
  {
    name: "Drag And Drop",
    img_path: "/userHome/dragAndDrop.jpeg",
    value: "DragAndDrop",
  },
  {
    name: "Circle Or Select",
    img_path: "/userHome/circleOrSelect.jpeg",
    value: "CircleOrSelect",
  },
  {
    name: "Counting",
    img_path: "/userHome/counting.jpeg",
    value: "Counting",
  },
  {
    name: "Tick Or Check",
    img_path: "/userHome/tickOrCheck.jpeg",
    value: "TickOrCheck",
  },
  {
    name: "Match Or Pair",
    img_path: "/userHome/matchOrPair.jpeg",
    value: "MatchOrPair",
  },
  {
    name: "Typing Complete",
    img_path: "/userHome/typingComplete.jpeg",
    value: "TypingComplete",
  },
  {
    name: "Coloring Fun",
    img_path: "/userHome/coloringFun.jpeg",
    value: "ColoringFun",
  },
  {
    name: "Reading Complete",
    img_path: "/userHome/readingComplete.jpeg",
    value: "ReadingComplete",
  },
];

let backgroundTracks = [
  "AStrollInTheEvening_106_Ending_JHungerX.wav",
  "AtlanticTunes%20-%20Funky/AtlanticTunes%20-%20Funky.mp3",
  "Better%20Place%20139%20BPM.mp3",
  "Boo%20Boo%20125%20BPM.mp3",
  "business-groove-2024-09-26-10-50-50-utc/Business%20Groove%20short.mp3",
  "business-groove-2024-09-26-10-50-50-utc/Business%20Groove.mp3",
  "calmness-2024-08-21-22-58-19-utc/Calmness.mp3",
  "Carousel%20Melody%20120%20BPM.mp3",
  "casino-music-is-2024-08-22-12-39-44-utc/Jazz%2075%20MIX%201%20Minute%20Version.wav",
  "casino-music-is-2024-08-22-12-39-44-utc/Jazz%2075%20MIX%2030%20Second%20Version.wav",
  "casino-music-is-2024-08-22-12-39-44-utc/Jazz%2075%20MIX%20Loop%20Version%201.wav",
  "casino-music-is-2024-08-22-12-39-44-utc/Jazz%2075%20MIX%20Loop%20Version%202.wav",
  "Chocolate%20Chip%20Melody%20150%20BPM.mp3",
  "christmas/1.mp3",
  "christmas/2.mp3",
  "christmas/3.mp3",
  "christmas/4.mp3",
  "christmas-ragtime-piano-medley-2024-08-21-17-03-49-utc/Christmas%20Ragtime%20Medley_compressed.mp3",
  "Colors%20135%20BPM.mp3",
  "Come%20Take%20a%20Ride%20142%20BPM.mp3",
  "comedy-2024-08-23-02-08-06-utc/thehappywalkbacking.mp3",
  "comedy-2024-08-23-02-08-06-utc/thehappywalkpiano1.mp3",
  "comedy-2024-08-23-02-08-06-utc/thehappywalkpiano2.mp3",
  "comedy-2024-08-23-02-08-06-utc/thehappywalkwhistles.mp3",
  "comedy-2024-08-23-02-08-06-utc/the_happy_walk.mp3",
  "emotional-piano-2024-08-31-01-26-33-utc/Full%20Track.mp3",
  "Enjoy%20Your%20Day/Enjoy%20Your%20Day.mp3",
  "Fish%20Salon%20Meloy%20104%20BPM.mp3",
  "folk-acoustic-calm-guitar-2024-08-21-04-52-25-utc/Folk%20Acoustic%20Calm%20Guitar.mp3",
  "food-2024-08-23-02-29-45-utc/Full%20Track.mp3",
  "food-2024-08-23-02-29-45-utc/Mid%20Track.mp3",
  "food-2024-08-23-02-29-45-utc/Short%20Track.mp3",
  "fun-playful-road-trip/1.mp3",
  "happy-clappy/1.mp3",
  "happy-clappy/2.mp3",
  "happy-clappy/3.mp3",
  "happy-fun-big-beat/1.mp3",
  "happy-fun-big-beat/2.mp3",
  "happy-fun-kids-2024-09-29-05-17-35-utc/happy%20fun%20kids%20(short%20edit%202).mp3",
  "happy-fun-kids-2024-09-29-05-17-35-utc/happy%20fun%20kids%20(short%20edit).mp3",
  "happy-fun-kids-2024-09-29-05-17-35-utc/happy%20fun%20kids.mp3",
  "happy-marimba-and-acoustic-guitar/1.mp3",
  "happy-swing-2024-08-21-18-09-25-utc/happy%20swing.mp3",
  "happy-swing-2024-08-21-18-09-25-utc/Loop%20version.mp3",
  "happy-upbeat/1.mp3",
  "happy-upbeat/2.mp3",
  "happy-upbeat/3.mp3",
  "happy-upbeat/4.mp3",
  "Hatchu%20Melody%20161%20BPM.mp3",
  "heaven-and-earth-christmas-carol-2024-08-21-22-26-09-utc/30%20sec%20by%20EvolvingVibes.mp3",
  "heaven-and-earth-christmas-carol-2024-08-21-22-26-09-utc/60%20sec%20by%20EvolvingVibes.mp3",
  "heaven-and-earth-christmas-carol-2024-08-21-22-26-09-utc/Main%20by%20EvolvingVibes.mp3",
  "heaven-and-earth-christmas-carol-2024-08-21-22-26-09-utc/Short%2020%20sec%20by%20EvolvingVibes.mp3",
  "Ice%20Cream%20Melody%20135%20BPM.mp3",
  "Ice%20Whiskey%20Arranged/NS-Ice%20Whiskey%20Arranged_Full.mp3",
  "Ice%20Whiskey%20Arranged/NS-Ice%20Whiskey%20Arranged_Loop_A.mp3",
  "Ice%20Whiskey%20Arranged/NS-Ice%20Whiskey%20Arranged_Loop_B.mp3",
  "Ice%20Whiskey%20Arranged/NS-Ice%20Whiskey%20Arranged_Short.mp3",
  "Ice%20Whiskey%20Arranged/NS-Ice%20Whiskey%20Arranged_Stinger.mp3",
  "In%20Our%20House%20Lower%20Meloy%20126%20BPM.mp3",
  "In%20Our%20House%20Meloy%20126%20BPM.mp3",
  "jingle-bells/1.mp3",
  "jingle-bells/2.mp3",
  "jingle-bells/3.mp3",
  "jingle-bells/4.mp3",
  "jingle-bells-2/1.mp3",
  "jingle-bells-2/2.mp3",
  "jingle-bells-2/3.mp3",
  "jingle-bells-2/4.mp3",
  "kornevmusic_thecorporate/kornevmusic_-_the_corporate.mp3",
  "Let's%20Dance%20Meloy%20124%20BPM.mp3",
  "lullaby-2024-08-21-22-39-00-utc/Lullaby%20Box.mp3",
  "lullaby-2024-08-21-22-39-00-utc/Lullaby%20Full.mp3",
  "Main%20Files/Dreaming%20of%20You1.mp3",
  "Mario%20Melody%20123%20BPM.mp3",
  "memories-2024-08-21-10-28-00-utc/Memories.mp3",
  "moonlight-lullaby-2024-08-23-20-49-23-utc/Moonlight%20Lullaby.mp3",
  "Music%20Box/Music%20Box%20Master.mp3",
  "my-guitar/1.mp3",
  "Nothing%20We%20Can't%20Melody%20131%20BPM%202.mp3",
  "positive-sides-2024-08-23-02-51-23-utc/Villatic%20-%20Positive%20Sides%20(medium).mp3",
  "positive-sides-2024-08-23-02-51-23-utc/Villatic%20-%20Positive%20Sides%20(short%20loop).mp3",
  "positive-sides-2024-08-23-02-51-23-utc/Villatic%20-%20Positive%20Sides.mp3",
  "quirky-comedy/1.mp3",
  "quirky-comedy/2.mp3",
  "quirky-comedy/3.mp3",
  "quirky-comedy/4.mp3",
  "quirky-comedy/5.mp3",
  "Rainbow%20Meloy.mp3",
  "Red%20Caboose%20Melody%20115%20BPM.mp3",
  "redeeming-the-past-2024-08-23-02-27-47-utc/Redeeming%20the%20Past%20Loop%201.wav",
  "redeeming-the-past-2024-08-23-02-27-47-utc/Redeeming%20the%20Past%20Loop%202.wav",
  "redeeming-the-past-2024-08-23-02-27-47-utc/Redeeming%20the%20Past%20Loop%203.wav",
  "redeeming-the-past-2024-08-23-02-27-47-utc/Redeeming%20the%20Past%20Loop%204.wav",
  "redeeming-the-past-2024-08-23-02-27-47-utc/Redeeming%20the%20Past.mp3",
  "relaxing-corporate-2024-08-23-02-22-50-utc/Relaxing%20Corporate.mp3",
  "Rubber%20Ducky%20Melody%20130%20BPM.mp3",
  "Sentimental.mp3",
  "Shool%20Is%20Over%20Melody%20130%20BPM.mp3",
  "smooth-acoustic-2024-08-21-22-25-04-utc/Smooth%20Acoustic.mp3",
  "Special%20Parade%20Melody%20122%20BPM.mp3",
  "successful-day-2024-08-25-13-02-25-utc/Successful%20day.mp3",
  "Summer's%20Here%20Melody%20130%20BPM.mp3",
  "summer-playful-2024-09-25-17-32-17-utc/Summer%20Playful(2).mp3",
  "summer-playful-2024-09-25-17-32-17-utc/Summer%20Playful(3).mp3",
  "summer-playful-2024-09-25-17-32-17-utc/Summer%20Playful(4).mp3",
  "summer-playful-2024-09-25-17-32-17-utc/Summer%20Playful.mp3",
  "swing-jazz-2024-08-21-19-50-22-utc/On%20The%20Jazz%2060.mp3",
  "swing-jazz-2024-08-21-19-50-22-utc/On%20The%20Jazz.mp3",
  "Thanksgiving%20Kind%20Ballad/1-Thanksgiving%20Kind%20Ballad_Full.mp3",
  "Thanksgiving%20Kind%20Ballad/2-Thanksgiving%20Kind%20Ballad_Short.mp3",
  "Thanksgiving%20Kind%20Ballad/3-Thanksgiving%20Kind%20Ballad_Full_Loop.mp3",
  "Thanksgiving%20Kind%20Ballad/4-Thanksgiving%20Kind%20Ballad_Stinger.mp3",
  "the-french-jazz-romantic-2024-09-16-02-52-10-utc/The%20French%20Jazz_1_(full%20version%202.53).mp3",
  "the-french-jazz-romantic-2024-09-16-02-52-10-utc/The%20French%20Jazz_2_(1.31).mp3",
  "this-comedy-2024-08-23-02-07-58-utc/comedy_20160829.mp3",
  "Toshko%20Meloy%20115%20BPM.mp3",
  "Tropical%20Heat/Tropical%20Heat.mp3",
  "Twins%20Melody%2076%20BPM.mp3",
  "upbeat-energetic-summer-pop/1.mp3",
  "upbeat-energetic-summer-pop/2.mp3",
  "upbeat-energetic-summer-pop/3.mp3",
  "upbeat-energetic-summer-pop/4.mp3",
  "upbeat-energetic-summer-pop/5.mp3",
  "Weather%20Birdy%20Melody%20128%20BPM.mp3",
  "Yellow%20Bonnet%20128%20BPM.mp3"
]

const getRandomBackgroundTrack = () => {
  return backgroundTracks[Math.floor(Math.random() * backgroundTracks.length)];
}

export default { gameTypes, getRandomBackgroundTrack, };
