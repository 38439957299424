import { useEffect, useState } from "react";
import { NavBar, CollapsedNavBar } from "../components/newNavbar/navbar";
import FlashcardUploadModal from "../components/flashcards/FlashcardUploadModal/FlashcardUploadModal";
import FlashcardView from "../components/flashcards/FlashcardView/FlashcardView";
import * as bootstrap from "bootstrap";

const UploadFlashcard = () => {
  const [newPDF, setNewPDF] = useState(null);
  const [editedPDF, setEditedPDF] = useState(null);

  const showModal = () => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById("exampleModal")
    );
    myModal.show();
  };

  const editPdfHandler = (pdf) => {
    setEditedPDF(pdf);
    showModal();
  };

  useEffect(() => {
    const myModalEl = document.getElementById("exampleModal");
    myModalEl.addEventListener("hidden.bs.modal", (event) => {
      setEditedPDF(null);
    });
  }, []);

  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <button
          type="button"
          className="btn"
          style={{
            backgroundColor: "#845adf",
            color: "white",
            fontWeight: 700,
            position: "absolute",
            top: 0,
            right: 0,
            margin: "20px 40px 0 0",
          }}
          onClick={showModal}
        >
          Add New Flashcard
        </button>
        <FlashcardUploadModal editedPDF={editedPDF} setNewPDF={setNewPDF} />
        <FlashcardView editPdfHandler={editPdfHandler} newPDF={newPDF} />
      </div>
    </div>
  );
};

export default UploadFlashcard;
