import { validateEmail } from "../../scripts/validations";
import { useState } from "react";
import { authRoute } from "../../api/axios";

const ResetPassForm = () => {
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");

  const handleReset = async (e) => {
    e.preventDefault();
    setErr("");
    if (!validateEmail(email)) {
      setErr("Invalid email");
      return;
    }
    try {
      const res = await authRoute.post(
        "/resetpass",
        JSON.stringify({
          email: email,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res.data);
      alert("An email was sent to you with further instructions");
    } catch (e) {
      console.log(e);
      if (!e?.response) {
        setErr("No server response");
      } else if (e.response?.status == 403) {
        setErr("This email is not associated with an account");
      } else {
        setErr("Unexpected error");
      }
    }
  };

  return (
    <div className="flexColumn width100 flexCenterXY">
      <div style={{ height: "80px" }} className="d-none d-sm-block" />
      <form
        className="signupForm flexCenterXY flexColumn loginColor pt-5 pt-md-3"
        onSubmit={handleReset}
      >
        <h2>Reset Password</h2>
        <h3 className="p-4">
          Enter your email, and a link to reset your password will be sent to
          you
        </h3>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          className="inputText"
          placeholder="Parent Email"
        />
        <button
          className="siteButton px-5 py-3 mt-4 loginSignupButton"
          style={{ color: "black", fontSize: "20px" }}
          type="submit"
        >
          Reset Password
        </button>
        <p>{err}</p>
        <p
          style={{ textDecoration: "underline", color: "black" }}
          className="mt-3"
        >
          Go Back
        </p>
      </form>
      <div style={{ height: "80px" }} className="d-none d-sm-block" />
    </div>
  );
};

export default ResetPassForm;
