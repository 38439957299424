import { useNavigate } from "react-router-dom";
import { useModal } from "../../context/LoginModalContext";
import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";

const PricingCont = (props) => {
  const [decodedData, setDecodedData] = useState({});
  const { showModal } = useModal();
  const navigate = useNavigate();
  const { auth } = useAuth();

  useEffect(() => {
    if (auth && auth.accessToken) {
      setDecodedData(jwt_decode(auth.accessToken));
    } else {
      setDecodedData({});
    }
  }, [auth]);

  return (
    <div className="pricing-container">
      <h3>{props.period}</h3>
      <div
        className="d-flex gap-2"
        style={{
          alignItems: "center",
        }}
      >
        <h3>$ {props.price}</h3>
        <h4>per {props.noun}</h4>
      </div>
      <p>{props.description}</p>
      <button
        onClick={() => {
          if (!Object.keys(auth).length) {
            showModal("LOGIN");
          } else {
            navigate("pay", { state: { data: { plan: props } } });
          }
        }}
        className="btn btn-primary"
      >
        Buy now
      </button>
    </div>
  );
};
export default PricingCont;
