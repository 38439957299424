import { useEffect, useState } from "react";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import { NavBar, CollapsedNavBar } from "../components/newNavbar/navbar";
import moment from "moment";

const AdminPaymentHistory = () => {
  const [allUsers, setAllUsers] = useState(null);
  const authRoute = usePrivateRoute();

  useEffect(() => {
    authRoute.get(`/history/payments`).then((response) => {
      setAllUsers(response.data);
    });
  }, []);

  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <div className="limitWidth mx-5">
          <table className="w-100">
            <thead>
              <tr>
                <th>ID</th>
                <th>Payment</th>
                <th>Date</th>
                <th>Email Address</th>
              </tr>
            </thead>
            <tbody>
              {allUsers?.data &&
                allUsers.data.map((el) => {
                  return (
                    <tr key={el._id}>
                      <td>{el._id}</td>
                      <td>{el.name}</td>
                      <td>{moment(Date.now()).format()}</td>
                      <td>{el.email}</td>
                    </tr>
                  );
                })}
            </tbody>
            {allUsers?.data && allUsers.data.length == 0 && (
              <h1>No users found</h1>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminPaymentHistory;
