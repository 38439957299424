import { NavBar, CollapsedNavBar } from "../components/newNavbar/navbar";
import { useEffect, useState } from "react";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import { Skeleton } from "@mui/material";

const Admin = () => {
  const authRoute = usePrivateRoute();
  let [stats, setStats] = useState({});
  useEffect(() => {
    authRoute.get(`/stats`).then((data) => {
      console.log(data.data);
      setStats(data.data);
    });
  }, []);

  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <div className="statsContainer">
          {Object.keys(stats).length != 0
            ? Object.keys(stats)?.map((key, i) => (
                <div
                  className="statBox"
                  key={i}
                  style={{
                    backgroundColor: `rgba(${(i * 50) % 255}, ${
                      (i * 80) % 255
                    }, ${(i * 120) % 255}, 0.2)`,
                  }}
                >
                  <h3
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    <strong>{key.replace(/([a-z])([A-Z])/g, "$1 $2")}</strong>
                  </h3>
                  <h3>{stats[key]}</h3>
                </div>
              ))
            : Array.from({ length: 10 }, (_, index) => (
                <Skeleton
                  className="statBox"
                  sx={{
                    transform: "none",
                    top: "0",
                    left: "0",
                  }}
                >
                  <h3>Sekelton</h3>
                  <h3>Sekelton</h3>
                </Skeleton>
              ))}
        </div>
      </div>
    </div>
  );
};
export default Admin;
