import React, { useEffect } from "react";
import InnerHTML from "dangerously-set-html-content";
import Filters from "./games/Filters"


const GameComponent = ({ gameContent }) => {
  // This component used to show the game logic HTML
  // But the built in 'dangerouslySetInnerHTML' does not executing any <script> tags that in the HTML content
  // So it's using an opensource library that bypassed this limitation to include and run those <script> tags

  useEffect(() => {
    // Edit the "new game" btn content
    //if (document.querySelector(".winCont")) {
    //  document.querySelector(".winCont button.gameBtn").innerHTML = "Play Next Level";
    //}
    const changeBackgroundMusic = () => {
      window.gameVars.audioFiles.bgk = new Audio("/gamesAssets/audio/bgnd/" + Filters.getRandomBackgroundTrack())
      window.gameVars.audioFiles.bgk.volume = 0.1;
      window.gameVars.audioFiles.bgk.addEventListener("ended", () => {
        console.log("ended: ", window.gameVars.audioFiles.bgk)
        changeBackgroundMusic()
        window.gameVars.audioFiles.bgk.play()
        console.log("started: ", window.gameVars.audioFiles.bgk)
      });
    }
    changeBackgroundMusic()
  }, []);

  useEffect(() => {
    // componentWillUnmount
    return () => {
      if (window.gameVars.audioFiles) {
        Object.keys(window.gameVars.audioFiles).forEach((key) => {
          window.gameVars.audioFiles[key].pause();
        });
      }
      if (window.gameVars.audioFiles) {
        clearInterval(window.gameVars.confettiInterval);
      }
      if (window.gameVars.continueAnimating) {
        window.gameVars.continueAnimating = false;
      }
      console.log(window.gameVars.activeIntervals);
      if (window.gameVars.activeIntervals) {
        window.gameVars.activeIntervals.forEach((interval) => {
          clearInterval(interval);
        });
        window.gameVars.activeIntervals = [];
      }
    };
  }, []);

  // This line does not execute scripts
  // return <div dangerouslySetInnerHTML={{ __html: gameContent }} />;

  // This line does execute scripts
  return <InnerHTML html={gameContent} />;
};

export default GameComponent;
