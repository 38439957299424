import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./VideoItem.css";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import Swal from "sweetalert2";
import useAuth from "../../../hooks/useAuth";
import jwt_decode from "jwt-decode";

const VideoItem = (props) => {
  const { video, deleteVideoHandler, editVideo } = props;

  const authRoute = usePrivateRoute();

  const deleteVideo = () => {
    Swal.fire({
      title: "Are you sure you want to delete this video?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#26a042",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        authRoute
          .delete(`/videos/${video._id}`)
          .then((response) => {
            Swal.fire({
              title: "Deleted!",
              text: "The video has been deleted successfully.",
              icon: "success",
            });
            deleteVideoHandler(video._id);
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            Swal.fire({
              title: "Error!",
              html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
              icon: "error",
              confirmButtonText: "Try again",
            });
          });
      }
    });
  };

  const { auth } = useAuth();
  let decodedData = null;

  if (auth) {
    if (auth.accessToken) {
      decodedData = jwt_decode(auth.accessToken);
    }
  } else {
    decodedData = null;
  }

  return (
    <div id="video-container">
      <div id="video-item">
        <p id="video-name">{video.name}</p>
        <div id="video-item-icons">
          <FontAwesomeIcon
            className="btn"
            icon="fa-solid fa-pen"
            onClick={() => editVideo("VIDEO", video)}
          />
          <FontAwesomeIcon
            className="btn"
            icon="fa-solid fa-trash"
            onClick={deleteVideo}
            style={{ color: "#f60101" }}
          />
        </div>
      </div>
    </div>
  );
};

export default VideoItem;
