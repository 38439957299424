import { useEffect } from "react";
import PricingCont from "../components/membership/PricingCont";

const Membership = () => {
  const pricingOptions = [
    {
      period: "Monthly",
      price: "9.99",
      noun: "month",
      description: "One payment of $9.99, recurring every one month.",
      stripeId: "price_1OPBhcHBVpkHO6qhz5SuV9vV",
      testStripeId: "price_1NkvlEHBVpkHO6qhwnuxb8Xi",
    },
    {
      period: "Yearly",
      price: "100.00",
      noun: "year",
      description: "One payment of $100.00, recurring every twelve month.",
      stripeId: "price_1QEZlSHBVpkHO6qhNQPgWPHr",
      testStripeId: "price_1NkvlEHBVpkHO6qhAZk9xj45",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#E8E8E8",
      }}
    >
      <div
        className="membership"
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "calc(110px + 25px) 25px 25px 25px",
          gap: "15px",
        }}
      >
        <div
          style={{
            width: "fit-content",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontFamily: "BubbleGum Sans",
              width: "fit-content",
            }}
          >
            Join Now!
          </h1>
          <h4
            style={{
              fontFamily: "Poppins",
              width: "fit-content",
            }}
          >
            Choose a plan
          </h4>
        </div>
        <div id="container">
          <div id="membership-plans">
            {pricingOptions.map((option) => (
              <PricingCont key={option.period} {...option} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Membership;
