import { validatePassword } from "../../scripts/validations";
import { useState } from "react";
import { authRoute } from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";

const ActualPassForm = () => {
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");

  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  const token = params.get("token");

  const handleReset = async (e) => {
    e.preventDefault();
    setErr("");
    if (!validatePassword(password)) {
      setErr(
        "Please enter a password with 8 or more characters, with at least 1 uppercase letter and 1 number"
      );
      return;
    }
    try {
      const res = await authRoute.post(
        "/resetpass/reset",
        JSON.stringify({
          userId: id,
          token: token,
          password: password,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res.data);
      alert("Your password was successfuly changed!");
    } catch (e) {
      console.log(e);
      if (!e?.response) {
        setErr("No server response");
      } else if (e.response?.status == 403) {
        console.log(e.response);
        setErr(e.response.data);
      } else {
        setErr("Unexpected error");
      }
    }
  };

  return (
    <div className="flexColumn width100 flexCenterXY">
      <div style={{ height: "80px" }} className="d-none d-sm-block" />
      <form
        className="signupForm flexCenterXY flexColumn loginColor pt-5 pt-md-3"
        onSubmit={handleReset}
      >
        <h2>Reset Password</h2>
        <h3 className="p-4">Enter your new password</h3>
        <input
          type="password"
          name="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          className="inputText"
          placeholder="New password"
        />
        <button
          className="siteButton px-5 py-3 mt-4 loginSignupButton"
          style={{ color: "black", fontSize: "20px" }}
          type="submit"
        >
          Reset Password
        </button>
        <p>{err}</p>
      </form>
      <div style={{ height: "80px" }} className="d-none d-sm-block" />
    </div>
  );
};

export default ActualPassForm;
