import { useParams } from "react-router-dom";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import GameComponent from "../components/GameComponent";
import HelpButtonSticky from "../components/games/HelpButtonSticky";
import LoadingFrag from "../components/LoadingFrag";

const PlayGame = () => {
  const authRoute = usePrivateRoute();
  const params = useParams();
  const [game, setGame] = useState(null);
  const [loading, setLoading] = useState(true);
  const [found, setFound] = useState(false);
  const [currGame, setCurrgame] = useState(null);
  const [error, setError] = useState(false);
  const [vars, setVars] = useState(null);

  console.log(params.gameId);
  useEffect(() => {
    try {
      let gamesRes = authRoute
        .get(`/game/specific/${params.gameId}`)
        .then((data) => {
          console.log(data.data);
          setLoading(false);
          if (data.data.length > 0) {
            setGame(data.data[0]);
            if (data.data[0].gameVars) {
              setVars(data.data[0].gameVars);
            }
            setCurrgame(data.data[0].gameLogic);

            setFound(true);
          } else {
            setError("No such game exists!");
          }
        })
        .catch((e) => {
          setLoading(false);
          if (e.response.status == 402) {
            setError("This game is for premium users only!");
          } else {
            setError("An error has occured!");
          }
        });
      console.log(gamesRes);
    } catch {
      setLoading(false);
      setError("An error has occured!");
    }
  }, []);

  return (
    <div
      id="games-page"
      style={{
        padding: "110px 15% 0 15%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <p id="var-statements" style={{ display: "none" }}>
        {vars};
      </p>
      <div>
        {game && (
          <>
            <h1 className="gameTitle">{game.name}</h1>
            {game.objective && (
              <>
                <h4>Objective:</h4>
                <div dangerouslySetInnerHTML={{ __html: game.objective }}></div>
              </>
            )}
            {game.instructions && (
              <>
                <div className="flexRow">
                  <h4 style={{ width: "min-content" }}>Instructions:</h4>
                  <img
                    src="/userHome/playButton.svg"
                    className="blackPlay"
                    alt="Play Button"
                    width="30px"
                    height="30px"
                    onClick={() => {
                      let utterance = new SpeechSynthesisUtterance(
                        game.instructions
                      );
                      speechSynthesis.speak(utterance);
                    }}
                  />
                </div>
                <ul
                  className="mx-4"
                  dangerouslySetInnerHTML={{ __html: game.instructions }}
                ></ul>
              </>
            )}
          </>
        )}
        {loading && <LoadingFrag />}
      </div>
      <div className="gameContainer">
        {currGame && <GameComponent gameContent={currGame} />}
      </div>
      {game && game.videoEmbed && <HelpButtonSticky vidSrc={game.videoEmbed} />}
    </div>
  );
};

export default PlayGame;
