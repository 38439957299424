import { useMatch, useNavigate, useParams } from "react-router-dom";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import jwt_decode from "jwt-decode";
import { Skeleton } from "@mui/material";
import VideoCategory from "../components/videos/VideoCategory";

const VideoItem = ({ videoType }) => {
  const isActive = useMatch(`/videos/${encodeURIComponent(videoType.name)}`);
  const navigate = useNavigate();

  return (
    <a
      onClick={() => {
        navigate(`../${videoType.name}`, { replace: true });
      }}
      className={`video-type-button clickable ${isActive ? "active" : ""}`}
    >
      <p>{videoType.name}</p>
      <img title={videoType.name} src={videoType.image}></img>
    </a>
  );
};

const VideoDisplay = () => {
  const authRoute = usePrivateRoute();
  const params = useParams();
  const [videos, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [found, setFound] = useState(false);
  const [error, setError] = useState(false);
  const [userPremium, setUserPremium] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [playlists, setPlaylists] = useState(null);

  useEffect(() => {
    authRoute
      .get("/publicplaylists")
      .then((response) => {
        setPlaylists(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { auth } = useAuth();

  useEffect(() => {
    try {
      setUserPremium(jwt_decode(auth?.accessToken).premium);
    } catch (InvalidTokenError) {
      setUserPremium(null);
    }
  }, [auth]);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  useEffect(() => {
    setLoading(true);
    let videosRes = authRoute
      .get(`/publicvideos/${params.playlist}`)
      .then((data) => {
        console.log(data.data);
        setLoading(false);
        setGames(data.data);
        if (data.data.length > 0) {
          setFound(true);
        }
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
    console.log(videosRes);
  }, [params.playlist]);

  return (
    <div
      id="videos-page"
      style={{
        padding: "110px 0 0 0",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <div id="video-types-container" className="d-flex flex-column gap-15">
        <div
          className={`content d-flex flex-column gap-15 ${
            isCollapsed ? "collapsed" : ""
          }`}
        >
          {playlists &&
            playlists.map((playlist) => <VideoItem videoType={playlist} />)}
        </div>
        <button
          id="collapse-btn"
          className="btn"
          title={isCollapsed ? "Expand" : "Collapse"}
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <img src={`/userHome/${isCollapsed ? "expand" : "collapse"}.png`} />
        </button>
      </div>
      <div className="videos-container wide flex-grow-1">
        {loading
          ? Array.from({ length: 20 }, (_, index) => (
              <Skeleton
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "20px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                className={`my-container video-category wide-video`}
                sx={{
                  transform: "none",
                }}
              ></Skeleton>
            ))
          : videos.map((el) => {
              return (
                <VideoCategory
                  playGame
                  id={el._id}
                  key={el._id}
                  img={el.image}
                  name={el.name}
                  premPop={togglePopup}
                  premium={el.premium}
                  user={userPremium != null}
                />
              );
            })}
        {isPopupVisible && (
          <div
            className="popupCont"
            onClick={togglePopup}
            style={{
              position: "fixed",
            }}
          >
            <div className="popup">
              <h3>This video is for premium users only</h3>
              <div className="flexColumn">
                <button onClick={togglePopup} className="siteButton">
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoDisplay;
