import { Link, useResolvedPath, useMatch } from "react-router-dom";
import LoginButton from "../LoginButton";
import HeaderItem from "./HeaderItem";
import useAuth from "../../hooks/useAuth";
import HomeBtn from "../HomeBtn";

import Swal from "sweetalert2";

import "@fortawesome/fontawesome-free";
import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { Dropdown } from "bootstrap";
library.add(far, fas, fab);

const HomeNavbar = () => {
  const { auth } = useAuth();

  return (
    <nav
      id="guest-navbar"
      className="navbar navbar-expand-lg navbar-dark bg-dark"
    >
      <Link to={"/"} className="navbar-brand">
        <img src="/logo.svg" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className="collapse navbar-collapse"
        id="navbarSupportedContent"
        style={{
          justifyContent: "end",
          gap: "20px",
        }}
      >
        <ul className="navbar-nav mr-auto">
          <HeaderLink to="/" value="Home" />
          <HeaderLink to="/games" value="Games" />
          <HeaderLink to="/worksheets" value="Worksheets" />
          <HeaderLink to="/flashcards" value="Flashcards" />
          <HeaderLink to="/videos" value="Videos" />
          <ExternalLink to="https://clubbaby.com/blog/" value="Blog" />
          <DropMenu />
        </ul>

        {auth?.accessToken ? <HomeBtn /> : <LoginButton />}
      </div>
    </nav>
  );
};

const DropMenu = () => {
  const dropdownStyles = `.dropdown {
      cursor: pointer;
    }
    .dropdown> h2{
      font-family: "Bubblegum Sans" !important;
      font-weight: normal !important;
    }
    .dropdownContent {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.2s linear;      
      position: absolute;
      border-radius: 4px;
      background:black;
      padding: 5px;
    }
    .dropdownContent > * {
      display: block;
      opacity: 0.7;
    }
    .dropdownContent> *:hover{
      opacity: 1;
      border-bottom: 2px solid white;
      transition: 0.1s;
    }
    .dropdown>h2:hover{
      opacity:0.6
    }
    .dropdown:hover .dropdownContent {
      visibility: visible;
      opacity: 1;
    }`;
  return (
    <>
      <style>{dropdownStyles}</style>
      <li className="nav-item mt-3 mt-lg-0">
        <div className="dropdown headerItem navItem">
          <h2>Store</h2>
          <ul className="dropdownContent">
            <ExternalLink
              to="https://clubbaby.com/club-baby-music-store/?filter_by_type=collections&ordering_by=post_date"
              value="Music"
            />
            <ExternalLink
              to="https://beyondt-shirts.com/pages/club-baby"
              value="Merchandise"
            />
          </ul>
        </div>
      </li>
    </>
  );
};

const ExternalLink = ({ to, value }) => {
  return (
    <li
      className="nav-item mt-3 mt-lg-0"
      data-toggle="collapse"
      data-target=".navbar-collapse.show"
      onClick={(e) => {
        window.open(to, "_blank");
      }}
    >
      <a className="headerItem navItem">
        <h2 style={{ cursor: "pointer" }}>{value}</h2>
      </a>
    </li>
  );
};

const Header = () => {
  const currentPath = useResolvedPath();
  return currentPath.pathname.startsWith("/admin") ? <></> : <HomeNavbar />;
};

const HeaderLink = ({ to, children, value, ...props }) => {
  const currentPath = useResolvedPath();
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({
    path: resolvedPath.pathname,
    end: to === "/",
  });
  return (
    <li
      className="nav-item mt-3 mt-lg-0 nav-item"
      data-toggle="collapse"
      data-target=".navbar-collapse.show"
    >
      <HeaderItem isFocused={isActive} value={value} link={to} />
    </li>
  );
};

export default Header;
