import { useEffect, useState } from "react";
import VideoCategory from "../components/videos/VideoCategory";
import usePrivateRoute from "../hooks/useAxiosPrivate";

const VideosCategories = () => {
  const [playlists, setPlaylists] = useState(null);
  const authRoute = usePrivateRoute();

  useEffect(() => {
    authRoute
      .get("/publicplaylists")
      .then((response) => {
        setPlaylists(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div
      id="videos-page"
      style={{
        padding: "110px 0 0 0",
        minHeight: "100vh",
        display: "flex",
        paddingBottom: "25px",
      }}
    >
      <div className="videos-container flex-grow-1">
        {playlists &&
          playlists.map((playlist) => {
            return (
              <VideoCategory
                key={playlist._id}
                img={playlist.image}
                name={playlist.name}
              />
            );
          })}
      </div>
    </div>
  );
};

export default VideosCategories;
