import { useParams } from "react-router-dom";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";

const PlayVideo = () => {
  const params = useParams();
  const authRoute = usePrivateRoute();

  const [loading, setLoading] = useState(true);
  const [video, setVideo] = useState(null);

  useEffect(() => {
    setLoading(true);

    authRoute
      .get(`/videos/specific/${params.videoId}`)
      .then((response) => {
        setVideo(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <div
      id="videos-page"
      style={{
        padding: "110px 15% 0 15%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {video && (
        <>
          <h1 className="gameTitle" style={{ fontSize: "35px" }}>
            {video.name}
          </h1>
          <iframe
            className="video"
            style={{
              width: "70%",
              aspectRatio: 16 / 9,
              borderRadius: "10px",
            }}
            src={`https://youtube.com/embed/${video.videoId}`}
          ></iframe>
        </>
      )}
    </div>
  );
};

export default PlayVideo;
