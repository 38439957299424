import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Filters from "../../worksheets/Filters";
import "./FlashcardItem.css";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import Swal from "sweetalert2";

const FlashcardItem = (props) => {
  const { pdf, deletePdfHandler, editPDF } = props;

  const authRoute = usePrivateRoute();

  const deletePDF = () => {
    Swal.fire({
      title: "Are you sure you want to delete this worksheet?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#26a042",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        authRoute
          .delete(`/flashcards/${pdf._id}`)
          .then((data) => {
            Swal.fire({
              title: "Deleted!",
              text: "The worksheet has been deleted successfully.",
              icon: "success",
            });
            deletePdfHandler(pdf._id);
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            Swal.fire({
              title: "Error!",
              html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
              icon: "error",
              confirmButtonText: "Try again",
            });
          });
      }
    });
  };

  return (
    <div id="pdf-container">
      <div id="pdf-item">
        <p id="pdf-name">{pdf.title}</p>
        <div id="pdf-item-icons">
          <FontAwesomeIcon
            className="btn"
            icon="fa-solid fa-pen"
            onClick={() => editPDF(pdf)}
          />
          <FontAwesomeIcon
            className="btn"
            icon="fa-solid fa-trash"
            style={{ color: "#f60101" }}
            onClick={deletePDF}
          />
        </div>
      </div>
    </div>
  );
};

export default FlashcardItem;
