import { useEffect, useState } from "react";
import { NavBar, CollapsedNavBar } from "../components/newNavbar/navbar";
import * as bootstrap from "bootstrap";
import { useNavigate } from "react-router-dom";
import VideoUploadModal from "../components/videos/VideoUploadModal/VideoUploadModal";
import AdminVideoView from "../components/videos/VideoView/NewVideoView";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import PlaylistUploadModal from "../components/videos/PlaylistUploadModal/PlaylistUploadModal";
import Swal from "sweetalert2";

const AdminVideos = () => {
  const [newVideo, setNewVideo] = useState(null);
  const [editedVideo, setEditedVideo] = useState(null);
  const [newPlaylist, setNewPlaylist] = useState(null);
  const [editedPlaylist, setEditedPlaylist] = useState(null);
  const [playlists, setPlaylists] = useState(null);

  const navigate = useNavigate();
  const authRoute = usePrivateRoute();

  const showModal = (type) => {
    const myModal = bootstrap.Modal.getInstance(
      document.getElementById(`admin-${type.toLowerCase()}-modal`)
    );
    myModal.show();
  };

  const editObjHandler = (type, obj) => {
    if (type === "VIDEO") {
      setEditedVideo(obj);
    } else if (type === "PLAYLIST") {
      setEditedPlaylist(obj);
    }
    showModal(type);
  };

  useEffect(() => {
    const myVideoModal = document.getElementById("admin-video-modal");
    myVideoModal.addEventListener("hidden.bs.modal", (event) => {
      setEditedVideo(null);
    });
    const myPlaylistModal = document.getElementById("admin-playlist-modal");
    myPlaylistModal.addEventListener("hidden.bs.modal", (event) => {
      setEditedPlaylist(null);
    });
  }, []);

  useEffect(() => {
    authRoute
      .get("/publicplaylists")
      .then((response) => {
        setPlaylists(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const deletePlaylistHandler = (id) => {
    setPlaylists((prev) => {
      let newPrev = [...prev];
      newPrev = newPrev.filter((playlist) => playlist._id !== id);
      return newPrev;
    });
  };

  const deletePlaylist = (playlist) => {
    Swal.fire({
      title: "Are you sure you want to delete this playlist?",
      html: "This action will delete any video under this playlist!<br>You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#26a042",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        authRoute
          .delete(`/playlists/${playlist._id}`)
          .then((response) => {
            Swal.fire({
              title: "Deleted!",
              text: "The playlist has been deleted successfully.",
              icon: "success",
            });
            deletePlaylistHandler(playlist._id);
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
            Swal.fire({
              title: "Error!",
              html: `Something went wrong. Let's give this another try.<br><br>ERROR: "${error.response.data}"`,
              icon: "error",
              confirmButtonText: "Try again",
            });
          });
      }
    });
  };

  useEffect(() => {
    if (newPlaylist) {
      console.log(playlists);

      const index = playlists.findIndex(
        (playlist) => playlist._id === newPlaylist._id
      );

      if (index !== -1) {
        setPlaylists(
          playlists.map((playlist, i) => (i === index ? newPlaylist : playlist))
        );
      } else {
        setPlaylists((prevPlaylists) => [...prevPlaylists, newPlaylist]);
      }
    }
  }, [newPlaylist]);

  return (
    <div id="page-container">
      <NavBar />
      <CollapsedNavBar />
      <div id="page-content">
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            margin: "20px 40px 0 0",
            display: "flex",
            flexDirection: "row-reverse",
            gap: "15px",
          }}
        >
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: "#845adf",
              color: "white",
              fontWeight: 700,
            }}
            onClick={() => {
              showModal("VIDEO");
            }}
          >
            Add New Video
          </button>
          <button
            type="button"
            className="btn"
            style={{
              backgroundColor: "#50ff50",
              color: "white",
              fontWeight: 700,
            }}
            onClick={() => {
              showModal("PLAYLIST");
            }}
          >
            Add New Playlist
          </button>
        </div>
        <VideoUploadModal
          editedVideo={editedVideo}
          setNewVideo={setNewVideo}
          playlists={playlists}
        ></VideoUploadModal>
        <PlaylistUploadModal
          editedPlaylist={editedPlaylist}
          setNewPlaylist={setNewPlaylist}
        ></PlaylistUploadModal>
        <AdminVideoView
          editObjHandler={editObjHandler}
          newVideo={newVideo}
          newPlaylist={newPlaylist}
          playlists={playlists}
          deletePlaylist={deletePlaylist}
        ></AdminVideoView>
      </div>
    </div>
  );
};

export default AdminVideos;
