import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Filters from "../../worksheets/Filters";
import "./FlashcardItemsWrapper.css";
import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import FlashcardItem from "../FlashcardItem/FlashcardItem";

const FlashcardItemsWrapper = (props) => {
  const {
    subjectName,
    totalCount,
    setCurrTotalCount,
    editPdfHandler,
    newPDF,
    subjectChanged,
  } = props;
  const [currPage, setCurrPage] = useState(1);
  const [pdfs, setPdfs] = useState([]);
  const [isLoadingPDFs, setIsLoadingPDFs] = useState(true);

  const authRoute = usePrivateRoute();

  const pages = Math.ceil(totalCount / 10);

  const handleChange = (event, value) => {
    setCurrPage(value);
  };

  useEffect(() => {
    setIsLoadingPDFs(true);
    authRoute
      .get(`/flashcards/allfast?subject=${subjectName}&page=${currPage}`)
      .then((data) => {
        console.log(data.data.data);
        setPdfs(data.data.data);
        setIsLoadingPDFs(false);
      });
  }, [currPage]);

  const deletePdfHandler = (id) => {
    setPdfs((prev) => {
      let newPrev = [...prev];
      newPrev = newPrev.filter((pdf) => pdf._id !== id);
      return newPrev;
    });
    setCurrTotalCount({
      subjectName,
      subjectCountFunc: (count) => count - 1,
      totalCountFunc: (count) => count - 1,
    });
  };

  useEffect(() => {
    if (newPDF?.subject === subjectName) {
      const index = pdfs.findIndex((pdf) => pdf._id === newPDF._id);

      if (index !== -1) {
        setPdfs(pdfs.map((pdf, i) => (i === index ? newPDF : pdf)));
      } else {
        setPdfs([newPDF, ...pdfs]);

        if (!newPDF.oldSubject) {
          setCurrTotalCount({
            subjectName,
            subjectCountFunc: (count) => count + 1,
            totalCountFunc: (count) => count + 1,
          });
        } else {
          subjectChanged(newPDF.oldSubject, newPDF.subject)
        }
      }
    } else if (newPDF?.oldSubject == subjectName) {
      setPdfs((prev) => {
        let newPrev = [...prev];
        newPrev = newPrev.filter((pdf) => pdf._id !== newPDF._id);
        return newPrev;
      });
    }
  }, [newPDF]);

  return (
    <div id="pdfs-wrapper">
      {totalCount == 0 ? (
        <h4>No PDFs to show</h4>
      ) : !isLoadingPDFs ? (
        <div>
          {pdfs?.map((pdf) => {
            return (
              <FlashcardItem
                key={pdf._id}
                pdf={pdf}
                deletePdfHandler={deletePdfHandler}
                editPDF={editPdfHandler}
              />
            );
          })}
          {pages > 1 ? (
            <Pagination
              id="pdfs-pagination"
              count={pages}
              onChange={handleChange}
              page={currPage}
              shape="rounded"
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div>Loading PDFs...</div>
      )}
    </div>
  );
};

export default FlashcardItemsWrapper;
