let gradeFilters = [
  {
    text: "Pre-K",
    search: "Pre-K",
    img_path: "/homeScreen/kindergarten-grade.jpeg",
  },
  {
    text: "Kindergarten",
    search: "KG",
    img_path: "/homeScreen/kindergarten-grade.jpeg",
  },
  {
    text: "Grade 1",
    search: "1st Grade",
    img_path: "/homeScreen/grade-1.jpeg",
  },
  {
    text: "Grade 2",
    search: "2nd Grade",
    img_path: "/homeScreen/grade-2.jpeg",
  },
  {
    text: "Grade 3",
    search: "3rd Grade",
    img_path: "/homeScreen/grade-3.jpeg",
  },
];
let subjectFilters = [
  { text: "N/A", search: "NA" },
  { text: "English", search: "English" },
  { text: "Math", search: "Math" },
  { text: "Skills", search: "Skills" },
  { text: "Art", search: "Art" },
  { text: "Coloring Book", search: "Coloring Book" },
  { text: "Storybook", search: "Storybook" },
];
let sortMethods = {
  "Most Downloaded": "mostdownloaded",
  "Most Viewed": "mostviewed",
  "New to Old": "newest",
  "Old to New": "oldest",
  "A - Z": "alphabetical",
  "Z - A": "reversealphabetical",
};

export default { gradeFilters, subjectFilters, sortMethods };
