import { useEffect, useState } from "react";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import "./NewVideoView.css";
import { Skeleton } from "@mui/material";
import VideoItemsWrapper from "../VideoItemsWrapper/VideoItemsWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminVideoView = (props) => {
  const { editObjHandler, newVideo, newPlaylist, playlists, deletePlaylist } =
    props;

  const [subjectCount, setSubjectCount] = useState(null);

  const authRoute = usePrivateRoute();

  useEffect(() => {
    authRoute.get(`/videos/count`).then((data) => {
      if (data.data == "") {
        setSubjectCount({});
      } else {
        setSubjectCount(data.data);
      }
    }, {});
  }, [newPlaylist]);

  const setCurrTotalCount = ({
    playlistName,
    subjectCountFunc = (number) => number,
    totalCountFunc = (number) => number,
  }) => {
    const updatedCountsBySubject = {
      ...subjectCount.countsBySubject,
      [playlistName]: subjectCountFunc(
        subjectCount.countsBySubject[playlistName] ?? 0
      ),
    };

    const newSubjectCount = {
      ...subjectCount,
      totalCount: totalCountFunc(subjectCount.totalCount),
      countsBySubject: updatedCountsBySubject,
    };

    setSubjectCount(newSubjectCount);
  };

  const subjectChanged = (oldSubject, newSubject) => {
    const updatedCountsBySubject = {
      ...subjectCount.countsBySubject,
      [oldSubject]: (subjectCount.countsBySubject[oldSubject] ?? 0) - 1,
      [newSubject]: (subjectCount.countsBySubject[newSubject] ?? 0) + 1,
    };

    const newSubjectCount = {
      ...subjectCount,
      countsBySubject: updatedCountsBySubject,
    };

    setSubjectCount(newSubjectCount);
  };

  return (
    <div id="video-view-container">
      <div id="total-count">
        Total {subjectCount ? subjectCount.totalCount : 0} Videos
      </div>
      {playlists &&
        playlists.map((playlist, index) => {
          const currentID = `collapse${playlist.name.split(" ").join("")}`;
          const currentSubjectTextsID = `texts-${playlist.name
            .split(" ")
            .join("")}`;
          const totalCount =
            subjectCount?.countsBySubject?.[playlist.name] ?? 0;

          return subjectCount ? (
            <div id="subject-button" key={playlist.name}>
              <div
                id={currentSubjectTextsID}
                key={index}
                value={playlist.name}
                className="video-collapse collapsed"
                data-toggle="collapse"
                data-target={"#" + currentID}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <img
                    style={{
                      height: "100px",
                      borderRadius: "20px",
                    }}
                    src={playlist.image}
                    className="square-image"
                    alt=""
                  ></img>
                  <p
                    style={{
                      alignContent: "center",
                    }}
                    id="subject-name"
                  >
                    {playlist.name}
                  </p>
                </div>
                <div id="right-side-texts">
                  <div id="video-item-icons">
                    <FontAwesomeIcon
                      className="btn"
                      icon="fa-solid fa-pen"
                      onClick={(e) => {
                        e.stopPropagation();
                        editObjHandler("PLAYLIST", playlist);
                      }}
                    />
                    <FontAwesomeIcon
                      className="btn"
                      icon="fa-solid fa-trash"
                      onClick={(e) => {
                        e.stopPropagation();
                        deletePlaylist(playlist);
                      }}
                      style={{ color: "#f60101" }}
                    />
                  </div>
                  <p id="subject-count">{totalCount}</p>
                  <div id="video-chevron"></div>
                </div>
              </div>
              <div className="collapse" id={currentID}>
                <VideoItemsWrapper
                  setCurrTotalCount={setCurrTotalCount}
                  totalCount={totalCount}
                  playlistName={playlist.name}
                  subjectChanged={subjectChanged}
                  editObjHandler={editObjHandler}
                  newVideo={newVideo}
                />
              </div>
            </div>
          ) : (
            <Skeleton key={playlist.name} className="video-collapse-skeleton" />
          );
        })}
    </div>
  );
};

export default AdminVideoView;
