import { useEffect, useState } from "react";
import usePrivateRoute from "../../../hooks/useAxiosPrivate";
import Filters from "../Filters";
import "./PDFView.css";
import PDFItemsWrapper from "../PDFItemsWrapper/PDFItemsWrapper";
import { Skeleton } from "@mui/material";

const NewPDFView = (props) => {
  const { editPdfHandler, newPDF, searchQuery } = props;

  const [currPage, setCurrPage] = useState(1);
  const [pdfs, setPdfs] = useState([]);
  const [isLoadingPDFs, setIsLoadingPDFs] = useState(true);
  const [count, setCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [tempSearchTerm, setTempSearchTerm] = useState("");
  const [gradeFilters, setGradeFilters] = useState("None");
  const [subjectFilters, setSubjectFilters] = useState("None");
  const [isEditing, setIsEditing] = useState({});

  const [subjectCount, setSubjectCount] = useState(null);

  const changePage = (howMuch) => {
    if (isLoadingPDFs) {
      return;
    }
    if (howMuch < 0) {
      if (currPage + howMuch > 0) {
        setCurrPage(currPage + howMuch);
      }
    } else {
      if (pdfs.length > 0) {
        if (currPage + howMuch <= Math.ceil(count / 20)) {
          setCurrPage(currPage + howMuch);
        }
      }
    }
  };

  const authRoute = usePrivateRoute();

  useEffect(() => {
    setSubjectCount(null);
    authRoute.get(`/pdfs/count?q=${searchQuery}`).then((data) => {
      if (data.data == "") {
        setSubjectCount({});
      } else {
        setSubjectCount(data.data);
      }
    });
  }, [searchQuery]);

  useEffect(() => {
    if (subjectCount && searchQuery) {
      const rowsToHighlight = document.querySelectorAll(
        `#subject-button .pdf-collapse`
      );
      console.log(rowsToHighlight);

      rowsToHighlight.forEach((row) => {
        if (row.querySelector("#subject-count").textContent > 0) {
          row.classList.add("blink-highlight");
          setTimeout(() => {
            row.classList.remove("blink-highlight");
          }, 1500);
        }
      });
    }
  }, [subjectCount]);

  const setCurrTotalCount = ({
    subjectName,
    subjectCountFunc = (number) => number,
    totalCountFunc = (number) => number,
  }) => {
    const updatedCountsBySubject = {
      ...subjectCount.countsBySubject,
      [subjectName]: subjectCountFunc(
        subjectCount.countsBySubject[subjectName] ?? 0
      ),
    };

    const newSubjectCount = {
      ...subjectCount,
      totalCount: totalCountFunc(subjectCount.totalCount),
      countsBySubject: updatedCountsBySubject,
    };

    setSubjectCount(newSubjectCount);
  };

  const subjectChanged = (oldSubject, newSubject) => {
    const updatedCountsBySubject = {
      ...subjectCount.countsBySubject,
      [oldSubject]: subjectCount.countsBySubject[oldSubject] - 1,
      [newSubject]: subjectCount.countsBySubject[newSubject] + 1,
    };

    const newSubjectCount = {
      ...subjectCount,
      countsBySubject: updatedCountsBySubject,
    };

    setSubjectCount(newSubjectCount);
  };

  return (
    <div id="pdf-view-container">
      <div id="total-count">
        Total {subjectCount ? subjectCount.totalCount ?? 0 : 0} Worksheets
      </div>
      {Filters.subjectFilters.map((filter, index) => {
        const currentID = `collapse${filter.search.split(" ").join("")}`;
        const currentSubjectTextsID = `texts-${filter.search
          .split(" ")
          .join("")}`;
        const totalCount = subjectCount?.countsBySubject?.[filter.search] ?? 0;

        return subjectCount ? (
          <div id="subject-button" key={filter.text}>
            <div
              id={currentSubjectTextsID}
              key={index}
              value={filter.search}
              className="pdf-collapse collapsed"
              data-toggle="collapse"
              data-target={"#" + currentID}
            >
              <p id="subject-name">{filter.text}</p>
              <div id="right-side-texts">
                <p id="subject-count">{totalCount}</p>
                <div id="pdf-chevron"></div>
              </div>
            </div>
            <div className="collapse" id={currentID}>
              <PDFItemsWrapper
                totalCount={totalCount}
                subjectName={filter.search}
                pdfs={pdfs[filter.text]}
                setCurrTotalCount={setCurrTotalCount}
                subjectChanged={subjectChanged}
                editPdfHandler={editPdfHandler}
                newPDF={newPDF}
                searchQuery={searchQuery}
              />
            </div>
          </div>
        ) : (
          <div id="subject-button" key={filter.text}>
            <Skeleton className="pdf-collapse-skeleton" />
          </div>
        );
      })}
    </div>
  );
};

export default NewPDFView;
