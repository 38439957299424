import { useMatch, useNavigate, useParams } from "react-router-dom";
import usePrivateRoute from "../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import jwt_decode from "jwt-decode";
import GameCategory from "../components/games/GameCategory";
import { Skeleton } from "@mui/material";
import Filters from "../components/games/Filters";

const GameTypeItem = ({ gameType }) => {
  const isActive = useMatch(`/games/${gameType.value}`);
  const navigate = useNavigate();

  return (
    <a
      onClick={() => {
        navigate(`../${gameType.value}`, { replace: true });
      }}
      className={`game-type-button clickable ${isActive ? "active" : ""}`}
    >
      <p>{gameType.name}</p>
      <img title={gameType.name} src={gameType.img_path}></img>
    </a>
  );
};

const GameDisplay = () => {
  const authRoute = usePrivateRoute();
  const params = useParams();
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [found, setFound] = useState(false);
  const [error, setError] = useState(false);
  const [userPremium, setUserPremium] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { auth } = useAuth();

  useEffect(() => {
    try {
      setUserPremium(jwt_decode(auth?.accessToken).premium);
    } catch (InvalidTokenError) {
      setUserPremium(null);
    }
  }, [auth]);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  useEffect(() => {
    setLoading(true);
    let gamesRes = authRoute
      .get(`/publicgames/${params.gameType}`)
      .then((data) => {
        console.log(data.data);
        setLoading(false);
        if (data.data.length > 0) {
          setGames(data.data);
          setFound(true);
        }
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
    console.log(gamesRes);
  }, [params.gameType]);

  return (
    <div
      id="games-page"
      style={{
        padding: "110px 0 0 0",
        minHeight: "100vh",
        display: "flex",
      }}
    >
      <div id="game-types-container" className="d-flex flex-column gap-15">
        <div
          className={`content d-flex flex-column gap-15 ${
            isCollapsed ? "collapsed" : ""
          }`}
        >
          {Filters.gameTypes.map((gameType) => (
            <GameTypeItem gameType={gameType} />
          ))}
        </div>
        <button
          id="collapse-btn"
          className="btn"
          title={isCollapsed ? "Expand" : "Collapse"}
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <img src={`/userHome/${isCollapsed ? "expand" : "collapse"}.png`} />
        </button>
      </div>
      <div className="games-container flex-grow-1">
        {loading
          ? Array.from({ length: 9 }, (_, index) => (
              <Skeleton
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "20px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                className="my-container game-category"
                sx={{
                  transform: "none",
                }}
              ></Skeleton>
            ))
          : games.map((el) => {
              return (
                <GameCategory
                  playGame
                  id={el._id}
                  key={el._id}
                  img={el.image}
                  name={el.name}
                  premPop={togglePopup}
                  premium={el.premium}
                  user={userPremium != null}
                />
              );
            })}
        {isPopupVisible && (
          <div
            className="popupCont"
            onClick={togglePopup}
            style={{
              position: "fixed",
            }}
          >
            <div className="popup">
              <h3>This game is for premium users only</h3>
              <div className="flexColumn">
                <button onClick={togglePopup} className="siteButton">
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GameDisplay;
